import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import SEO from '@/components/SEO/Seo'

import Layout from '@/components/Layout'
import Hero from '@/components/Hero/Hero'

const PolicyPageTemplate = ({ data, content }) => {
  return (
    <>
      <Hero
        heading={data.heading}
        fluid={data.background.childImageSharp.fluid}
      />
      <div className='flex items-center justify-center bg-white pt-20 py-32'>
        <div className='container'>
          <div
            className='text-black text-center policy flex items-center flex-col'
            dangerouslySetInnerHTML={{ __html: content }}
          />
          <b className='text-black text-center block'>
            Customers accept the T&Cs at the time of booking
          </b>
        </div>
      </div>
    </>
  )
}

const PolicyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout hideNav={true}>
      <SEO data={frontmatter.seo} />
      <PolicyPageTemplate
        data={frontmatter}
        content={data.markdownRemark.html}
      />
    </Layout>
  )
}

PolicyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export const pageQuery = graphql`
  query PolicyPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "policy" } }) {
      html
      frontmatter {
        title
        heading
        background {
          childImageSharp {
            fluid(quality: 45, maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        ...SEO
      }
    }
  }
`

export default PolicyPage
